<template>
    <div class="side-bar">
        <ul class="channel-list">
            <li class="active-channel" >
                <a @click="goHome" ><img src="@/assets/images/index_index_icon.png" /><span class="channel" >发现</span></a>
            </li>
            <li >
                <a ><img src="@/assets/images/index_post_icon.png" /><span class="channel" >发布</span></a>
            </li>
          
            <li v-if="isLogin" class="">
                <a ><img style="width:24px;height:24px;" :src="userInfo.avatar" /><span class="channel" >{{userInfo.nickname}}</span></a>
            </li>
        </ul>
        <el-button v-if="!isLogin" type="danger" round  @click="goLogin">登 录</el-button>
    </div>
</template>


<script>


import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'sidebar',
    computed: {
        ...mapGetters(['userInfo','isLogin'])
    },
    props: {

    },
    async created() {
		
        console.log( this.isLogin)
 
      
         
     },

    methods: {
        
        showDown(){
            if( this.isShowDown ){
                this.isShowDown =  false;
            } else {
                this.isShowDown =  true;
            }
        },

        goHome(){
            this.$router.replace('/');
        },

        goLogin(){
            this.$router.push('/login')
        }

    },

    data() {
        return {
        }
    },
}
</script>


<style lang="scss" scoped>

    .side-bar {
        width: 266.66667px;
        margin-left: 16px;
    }
    .side-bar{
        background-color: var(--color-background);
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        padding-top: 16px;
        margin-top: 72px;
        position: fixed;

        .channel-list{
            margin:10px 0px 20px 0;
        }
        .channel-list li{
            padding-left: 16px;
            min-height: 48px;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 8px;
            
            a{
                font-size: 20px;
                font-weight: 600;
            }
        }
        .channel {
            font-size: 16px;
            font-weight: 600;
            margin-left: 14px;
            color: var(--color-primary-label);
        }
        .active-channel {
            background-color: #f8f8f8;
            border-radius: 999px;
        }

        .channel-list img{
            vertical-align:middle;
        }
    }
</style>